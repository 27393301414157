import React from 'react';
import axios from 'axios';

function Loginform(props) {
    const email = React.useRef();
    const password = React.useRef();
    const [error,SetError] = React.useState({});
    const [payload,SetPayload] = React.useState({});
    const [loading,Seloading] = React.useState(false);

    const handleSubmit = e => {
        e.preventDefault();
      
        const data =  JSON.stringify({
          username: email.current.value,
          password: password.current.value,
        });
        
        var config = {
            method: 'POST',
            url: 'https://api.eureca.ao/v0.1/login',
            headers: {'Content-Type': 'application/json'},
            data:data
        };
        Seloading(true);
        axios(config)
        .then(function (response) {
            if(response.data?.error){
              console.log(response.data);
              SetError(response.data);
            }else{
              SetError({});
              SetPayload(response.data);
              localStorage.setItem("token",response.data.token);
              localStorage.setItem("refreshToken",response.data.refreshToken);
              window.location.href = '/';
            }
            Seloading(false);
        })
        .catch(function (error) {
            console.log(error);
            SetError(error);
            Seloading(false);
        });
      
    }

    return (
        <main>
        <section className="p-0 d-flex align-items-center position-relative overflow-hidden">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-6 m-auto">
                <div className="row my-5">
                  <div className="col-sm-10 col-xl-8 m-auto">
                    {/* Title */}
                    <h1 className="fs-2">Entrar</h1>
                    <p className="lead mb-4">
                     Entrar na sua conta como administrador
                    </p>
                    {/* Form START */}
                    <form onSubmit={handleSubmit}>
                      {/* Email */}
                      <div className="mb-4">
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="bi bi-envelope-fill" />
                          </span>
                          <input
                            ref={email}
                            type="email"
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="E-mail"
                            id="exampleInputEmail1"
                          />
                        </div>
                      </div>
                      {/* Password */}
                      <div className="mb-4">
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fas fa-lock" />
                          </span>
                          <input
                            ref={password}
                            type="password"
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="password"
                            id="inputPassword5"
                          />
                        </div>
                      </div>
                     <label htmlFor="" className='text-danger m-2'>{error.error}</label>
                      {/* Button */}
                      <div className="align-items-center mt-0">
                        <div className="d-grid">
                          <button className="btn btn-primary mb-0" type="submit">{loading?"Aguarde":"Entrar"}</button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>{" "}
                {/* Row END */}
              </div>
            </div>
          </div>
        </section>
      </main>
      
    );
}

export default Loginform;