import React from 'react';
import PlanCard from "../modules/PlanCard";

function Plans(props) {
    return (
        <main>
        <>
        {/* Page content START */}
        <div className="page-content">

            {/* Top bar END */}
            {/* Page main content START */}
            <div className="page-content-wrapper border">
            {/* Title */}
            <div className="row mb-3">
                <div className="col-12 d-sm-flex justify-content-between align-items-center">
                <h1 className="h3 mb-2 mb-sm-0">Planos</h1>
                
                </div>
            </div>
          
            {/* Card START */}
            <div className=" d-flex gap-3">
                {/* Card header START */}
                <PlanCard/>
                <PlanCard/>
                <PlanCard/>
            </div>
            {/* Card END */}
            </div>
            {/* Page main content END */}
        </div>
        {/* Page content END */}
        </>
        </main>
    );
}

export default Plans;