import React from 'react';
import logo from '../images/Eureca_Logo_Branco.png';
import { Link } from 'react-router-dom';

function Sidebar(props) {
    return (
     <nav className="navbar top-bar navbar-light border-bottom py-0 py-xl-3 bg-dark">
     <div className="container-fluid p-0">
         <div className="d-flex align-items-center w-100">

         <Link to={"/"} className="navbar-expand-xl">
            <img src={logo} width={100} height={50}/>
         </Link>

         {/* Top bar left */}
         <div className="navbar-expand-lg ms-auto ms-xl-0" style={{visibility:"hidden"}}>
             {/* Toggler for menubar START */}
             <button
             className="navbar-toggler ms-auto"
             type="button"
             data-bs-toggle="collapse"
             data-bs-target="#navbarTopContent"
             aria-controls="navbarTopContent"
             aria-expanded="false"
             aria-label="Toggle navigation"
             >
             <span className="navbar-toggler-animation">
                 <span />
                 <span />
                 <span />
             </span>
             </button>
             {/* Toggler for menubar END */}
             {/* Topbar menu START */}
             <div
             className="collapse navbar-collapse w-100"
             id="navbarTopContent"
             >
             {/* Top search START */}
             <div className="nav my-3 my-xl-0 flex-nowrap align-items-center">
                 <div className="nav-item w-100">
                 <form className="position-relative">
                     <input
                     className="form-control pe-5 bg-secondary bg-opacity-10 border-0"
                     type="search"
                     placeholder="Search"
                     aria-label="Search"
                     />
                     <button
                     className="bg-transparent px-2 py-0 border-0 position-absolute top-50 end-0 translate-middle-y"
                     type="submit"
                     >
                     <i className="fas fa-search fs-6 text-primary" />
                     </button>
                 </form>
                 </div>
             </div>
             {/* Top search END */}
             </div>
             {/* Topbar menu END */}
         </div>
         {/* Top bar left END */}
         {/* Top bar right START */}
         <div className="ms-xl-auto">
             <div className="navbar-nav flex-row align-items-center">
                <Link to={"/"}className="nav-item ms-2 ms-md-3 text-white">Home</Link>
                <Link to={"/admin/plans"}  className="nav-item ms-2 ms-md-3 text-white">Planos</Link>
                <Link to={"/admin/custom"} className="nav-item ms-2 ms-md-3 text-white">Personalizacão</Link>
             </div>
         </div>
         {/* Top bar right END */}
         </div>
     </div>
     </nav>
    );
}

export default Sidebar;