import axios from 'axios';
const MAIN_URL = localStorage.getItem("MAIN_URL");
const MAIN_HEADER = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + localStorage.getItem('token'),
};

const Network = {
  sendRequest: (method, url, data, headers) => {
    let config = {
      method: method,
      maxBodyLength: Infinity,
      url: MAIN_URL + url,
      headers: headers || MAIN_HEADER,
    };

    if (method.toLowerCase() === 'post') {
      config.data = data;
    } else if (method.toLowerCase() === 'get') {
      config.params = data;
    }

    return axios.request(config);
  },
  setToReview: (id, featured) => {
    return Network.sendRequest('post', '/admin/reviewrequest', {id: id,featured: featured,});
  },
  getconfigs: () => {
    return Network.sendRequest('get', '/content.json', {});
  },
  saveConfigs: (body) => {
    return Network.sendRequest('post', '/configs/save', body);
  },
  getUsersData: (params) => {
    return Network.sendRequest('get', '/users', params);
  },
  getUsersPlans: (params) => {
    return Network.sendRequest('get', '/plans', params);
  },
  getRequestApply: (userid) => {
    return Network.sendRequest('post', '/admin/applyviewer', {userid: userid});
  },
};

export default Network;