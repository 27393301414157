import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Network from "../network/networkRequests";
import ApplyInstructor from "./applyInstructor";

const DetailDialog = (props) => {
  const {open, handleClose, appID} = props;
  const [aplication,setaplication] =  useState({});

  useEffect(()=>{
    getApplyUser();
  },[]);

  async function getApplyUser(){
    await Network.getRequestApply(appID).then((response) => {
      setaplication(response.data);
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });   
  }

  return (
    <Dialog open={open} onClose={handleClose}  maxWidth="md">
      <DialogTitle>Analise da admissão</DialogTitle>
      <ApplyInstructor requestApply ={aplication}/>
    </Dialog>
  );
};

export default DetailDialog;
