import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 5,
    padding:"12px",
    boxShadow:"none",
    background:"#8000b229",
    textAlign: 'left',
  },
  header: {
    textAlign: 'left',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  price: {
    textAlign: 'left',
    textTransform:"bold"
  },
}));

const PricingCardDemo = (props) => {
  const {planDetail,selectedPlan} = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={
    <>
   { planDetail.isPupular &&
     <span className='popularConfig'>
        <Button variant="contained" fullWidth className={"shadow "+(selectedPlan == null || selectedPlan?.toString() != planDetail.id?.toString()?"bg-white":"mainColor")}>Popular</Button>
     </span>
   }
    <div className='d-flex flex-column'>
        <span className='h3'> {planDetail.price}.00Kzs</span>
        <span className="h5">{planDetail.name}</span>
    </div>
    </>} className="m-0 pb-0 pt-4" />
   
      <CardContent>
      <Divider variant="middle" />
        <div className="p-1">
          {planDetail.benefits.map((item,key)=>(<Typography key={key} align="left">{item}</Typography>))}
        </div>
      </CardContent>
      <Divider variant="middle" />
      <CardActions className={classes.action}>
        <Button variant="contained" fullWidth style={{height:"50px"}} className={"shadow "+(selectedPlan == null || selectedPlan?.toString() != planDetail.id?.toString()?"bg-white":"mainColor")} >Escolher</Button>
      </CardActions>
    </Card>

  )}

export default PricingCardDemo;