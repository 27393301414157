import React, { useState, useEffect } from 'react';
import {Actions,promiseToArray} from "./Actions";
import axios from 'axios';
import DetailDialog from './DetailDialog';
import Network from '../network/networkRequests';

function Main(props) {

const [userData, SetuserData ] =  React.useState([]);


async function getUsers(){
   await Network.getUsersData({}).then((response) => {
      if(response.data.error){
        console.log(response.data.error);
        window.localStorage.clear();
        return;
      }
        SetuserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });   
}

 async function setToReview(id,featured){
  const result = await Network.setToReview(id,featured);
  getUsers();
}

 useEffect(()=>{
    getUsers();
    // SetuserData(promiseToArray(getUsers()));
 },[]);

 const [aplicationID,setaplicatioID] =  useState("");
 const [openDialog,setopenDialog] =  useState(false);
 
    return (
        <main>
        <>
        {/* Page content START */}
        <div className="page-content">
            {/* Top bar END */}
            {/* Page main content START */}
            <div className="page-content-wrapper border">
            {/* Title */}
            <div className="row mb-3">
                <div className="col-12 d-sm-flex justify-content-between align-items-center">
                <h1 className="h3 mb-2 mb-sm-0">Dashboard</h1>
                
                </div>
            </div>
            {/* Course boxes START */}
            <div className="row g-4 mb-4">
                {/* Course item */}
                <div className="col-sm-6 col-lg-4">
                <div className="text-center p-4 bg-primary bg-opacity-10 border border-primary rounded-3">
                    <h6>Total de Alunos</h6>
                    <h2 className="mb-0 fs-1 text-primary">1200</h2>
                </div>
                </div>
                {/* Course item */}
                <div className="col-sm-6 col-lg-4">
                <div className="text-center p-4 bg-success bg-opacity-10 border border-success rounded-3">
                    <h6>Total de Explicadores</h6>
                    <h2 className="mb-0 fs-1 text-success">996</h2>
                </div>
                </div>
                {/* Course item */}
                <div className="col-sm-6 col-lg-4">
                <div className="text-center p-4  bg-warning bg-opacity-15 border border-warning rounded-3">
                    <h6>Total de aulas</h6>
                    <h2 className="mb-0 fs-1 text-warning">200</h2>
                </div>
                </div>
            </div>
            {/* Course boxes END */}
            {/* Card START */}
            <div className="card bg-transparent border">
                {/* Card header START */}
                <div className="card-header bg-light border-bottom">
                {/* Search and select START */}
                <div className="row g-3 align-items-center justify-content-between">
                    {/* Search bar */}
                    <div className="col-md-8">
                    <form className="rounded position-relative">
                        <input
                        className="form-control bg-body"
                        type="search"
                        placeholder="Pesquisar"
                        aria-label="Search"
                        />
                        <button
                        className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset"
                        type="submit"
                        >
                        <i className="fas fa-search fs-6 " />
                        </button>
                    </form>
                    </div>
                    {/* Select option */}
                    <div className="col-md-3">
                    {/* Short by filter */}
                    <form>
                        <select
                        className="form-select js-choice border-0 z-index-9"
                        aria-label=".form-select-sm"
                        >
                        <option value="">Filtrar por</option>
                        <option>Recente</option>
                        <option>Antigo</option>
                        <option>Aceito</option>
                        <option>Rejeitado</option>
                        </select>
                    </form>
                    </div>
                </div>
                {/* Search and select END */}
                </div>
                {/* Card header END */}
                {/* Card body START */}
                <div className="card-body">
                {/* Course table START */}
                <div className="table-responsive border-0 rounded-3">
                    {/* Table START */}
                    <table className="table table-dark-gray align-middle p-4 mb-0 table-hover w-100">
                    {/* Table head */}
                    <thead>
                        <tr>
                        <th scope="col" className="border-0 rounded-start">
                            Explicador
                        </th>
                        <th scope="col" className="border-0">
                            Ocupacão
                        </th>
                        <th scope="col" className="border-0">
                            Data
                        </th>
                        <th scope="col" className="border-0">
                            Genero
                        </th>
                        <th scope="col" className="border-0">
                            Preco
                        </th>
                        <th scope="col" className="border-0">
                            Estado
                        </th>
                        <th scope="col" className="border-0 rounded-end">
                            Opcoes
                        </th>
                        </tr>
                    </thead>
                    {/* Table body START */}
                    <tbody>
                        {userData.map((value,key)=>(<tr key={key}>
                        {/* Table data */}
                        <td>
                            <div className="d-flex align-items-center mb-3">
                            {/* Avatar */}
                            <div className="avatar avatar-xs flex-shrink-0">
                                <img
                                className="avatar-img rounded-circle"
                                src={value.profileImage}
                                alt="avatar"
                                />
                            </div>
                            {/* Info */}
                            <div className="ms-2">
                                <h6 className="mb-0 fw-light">{value.nome} {value.ultimoNome}</h6>
                            </div>
                            </div>
                        </td>
                        {/* Table data */}
                        <td>
                            <div className="d-flex align-items-center mb-3">
                            {/* Avatar */}
                            
                            {/* Info */}
                            <div className="ms-2">
                                <h6 className="mb-0 fw-light">{value.instructor?.ocupacao??"Nehuma"}</h6>
                            </div>
                            </div>
                        </td>
                        {/* Table data */}
                        <td>{value.data}</td>
                        {/* Table data */}
                        <td>
                            {" "}
                            <span className="badge text-bg-primary">{value.instructor?.genero??"indefinido"}</span>{" "}
                        </td>
                        {/* Table data */}
                        <td>{value.price}.00 Kzs</td>
                        {/* Table data */}
                        <td>
                            {value.featured == 1?<span className="badge bg-warning bg-opacity-15 text-warning">Em Análise</span>:value.featured == 2?<span className="badge bg-sucess bg-opacity-15 text-warning">Aprovado</span>:<span className="badge bg-info bg-opacity-15 text-info">Nao aplicado</span>}
                        </td>
                        {/* Table data */}
                        <td>
                            {value.featured == 1 && value.featured < 2? <button onClick={()=>{setToReview(value.id,2)}} className="btn btn-sm btn-success-soft me-1 mb-1 mb-md-0">Aprovar</button>: <button onClick={()=>{setToReview(value.id,1)}} className="btn btn-sm btn-secondary-soft me-1 mb-1 mb-md-0">Analisar</button>}
                            {value.featured >= 1?<button onClick={()=>{setToReview(value.id,0)}} className="btn btn-sm btn-danger-soft mb-0">Rejeitar</button>:""}
                           
                           {value.featured > 0 && <button className="btn btn-sm btn-info-soft m-1" onClick={()=>{
                                setopenDialog(true);
                                setaplicatioID(value.email);
                                }}>Ver Aplicacao</button>}
                        </td>
                        </tr>))}
                        
                        {/* Table row */}

                    </tbody>
                    {/* Table body END */}
                    </table>
                    {/* Table END */}
                </div>
                {/* Course table END */}
                </div>
                {/* Card body END */}
                {/* Card footer START */}
            
                {/* Card footer END */}
            </div>
            {/* Card END */}
            </div>
            {/* Page main content END */}
        </div>
        {/* Page content END */}
        </>
        {openDialog && <DetailDialog open={openDialog} appID={aplicationID} handleClose={()=>setopenDialog(false)}/>}
        </main>
    );
}

export default Main;