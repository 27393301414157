import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import HashLoader from "react-spinners/HashLoader"; 
import { fontSize } from '@mui/system';
import Chip from '@mui/material/Chip';
// import Camera from './camera';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PricingCardDemo from "../modules/PricingCardDemo";
import Button from '@material-ui/core/Button';
import Network from '../network/networkRequests';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ApplyInstructor(props) {
  const {requestApply} = props;

  const [plans,SetPlans] = React.useState([]);
  useEffect(()=>{
    getPlans();
  },[]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function getPlans(){
    await Network.getUsersPlans({}).then((response) => {
      SetPlans(response.data);
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });   
  }

  const [imageSrc, setImageSrc] = React.useState(null);

  const [showPopup, setShowPopup] = React.useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };



    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const occupation = React.useRef();
    const nascDia = React.useRef();
    const nascMes = React.useRef();
    const nascAno = React.useRef();
    const genero = React.useRef();
    const municipality = React.useRef();
    const provincia = React.useRef();
    const telefone = React.useRef();
    const jobtitle = React.useRef();
    const jobInit = React.useRef();
    const jobFinal = React.useRef();

    const instituicao = React.useRef();
    const scoolStart = React.useRef();
    const scoolEnd = React.useRef();
    const descricao = React.useRef();

    const skillsSet = React.useRef();

    const [habilidades,Sethabilidades] = React.useState([]);

    const [skills_instructor,SetSkills] = React.useState([]);
    // const [skill, skillSetInput] = React.useState(null);

    const update=(val)=>{
      // console.log(skillsSet.current.value);
      // skillSetInput(skillsSet.current.value);
    }

    const sendRequest= even =>{
        even.preventDefault();
        // handleClickOpen();
        setLoading(false);
        // const base_user_info = getDecodedToken();
        var base64 = require('base-64');
       
    }

    const addSkills=(item,value)=>{
      // SetSkills(skills_instructor => [...skills_instructor, item]);
      // Sethabilidades(habilidades.filter(item => item.title !== value));
    }

    return (
        <section className='bg-light'>
        <div className="container bg-white">
              {/* Form START */}
              <form className="row g-3" onSubmit={sendRequest}>
                <h5 className="mb-0">Informação pessoal</h5>
                
                {/* Gender */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col d-flex justify-content-between align-items-center bg-dark">
                    imagem de do bi e foto
                    </div>
               
                  </div>
                </div>
                {/* Date of birth */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">Data de nascimento</h6>
                    </div>
                    <div className="col-lg-8">
                      <div className="row g-2 g-sm-4">
                        <div className="col-4">
                          <select
                            className="form-select js-choice z-index-9 border-0 bg-light"
                            aria-label=".form-select-sm"
                            value={requestApply?.birth_date?.split("-")[0]}
                          >
                            <option >Dia</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <select
                            className="form-select js-choice z-index-9 border-0 bg-light"
                            aria-label=".form-select-sm"
                            value={requestApply?.birth_date?.split("-")[1]}
                          >
                            <option value="">Mês</option>
                            <option>Jan</option>
                            <option>Feb</option>
                            <option>Mar</option>
                            <option>Apr</option>
                            <option>Jun</option>
                            <option>Jul</option>
                            <option>Aug</option>
                            <option>Sep</option>
                            <option>Oct</option>
                            <option>Nov</option>
                            <option>Dec</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <select
                            className="form-select js-choice z-index-9 border-0 bg-light"
                            aria-label=".form-select-sm"
                            value={requestApply?.birth_date?.split("-")[2]}
                          >
                            <option value="">Ano</option>
                            <option>1990</option>
                            <option>1991</option>
                            <option>1992</option>
                            <option>1993</option>
                            <option>1994</option>
                            <option>1995</option>
                            <option>1996</option>
                            <option>1997</option>
                            <option>1998</option>
                            <option>1999</option>
                            <option>2000</option>
                            <option>2001</option>
                            <option>2002</option>
                            <option>2003</option>
                            <option>2004</option>
                            <option>2005</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                {/* Phone number */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                       Número de telefone <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                      <input type="text" className="form-control" id="phoneNumber"value={requestApply?.phone} />
                    </div>
                  </div>
                </div>
                {/* City */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                        Provincia <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                      <select
                        className="form-select js-choice z-index-9 border rounded bg-white"
                        aria-label=".form-select-sm"
                        value={requestApply?.province}
                      >
                       <option>Provincia</option>
                                <option>Bengo</option>
                                <option>Benguela</option>
                                <option>Bié</option>
                                <option>Cabinda</option>
                                <option>Cuando-Cubango</option>
                                <option>Cunene</option>
                                <option>Huambo</option>
                                <option>Huíla</option>
                                <option>Kwanza Sul</option>
                                <option>Kwanza Norte</option>
                                <option>Luanda</option>
                                <option>Lunda Norte</option>
                                <option>Lunda Sul</option>
                                <option>Malanje</option>
                                <option>Moxico</option>
                                <option>Namibe</option>
                                <option>Uíge</option>
                                <option>Zaire</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* State */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                       Minicipio <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                       <input type="text" value={requestApply?.street??"N/D"} placeholder="Kilamba Kiaxi" className="form-control" />
                    </div>
                  </div>
                </div>

                  {/* State */}
                  <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                       Apresentacao <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                      <textarea value={requestApply?.description} className="form-control z-index-9 rounded border bg-white" placeholder='Mais de 300 palavras...' ref={descricao}></textarea>
                    </div>
                  </div>
                </div>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Experiencia de trabalho</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      {/* Divider */}
                      <hr className="my-2" />
                          {/* Parent detail */}
                          {/* Salutation */}
                          <div className="col-12">
                            <div className="row g-xl-0 align-items-center">
                              <div className="col-lg-4">
                                <h6 className="mb-lg-0">
                                  Ocupação <span className="text-danger">*</span>
                                </h6>
                              </div>
                              <div className="col-lg-8">
                                <div className="d-flex">
                                
                                <input
                                    type="text" className="form-control" id="jobTitle"
                                     value={requestApply?.occupation}
                                      placeholder="Ocupação"
                                    />
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          {/* Job title */}
                          <div className="col-12">
                            <div className="row g-xl-0 align-items-center">
                              <div className="col-lg-4">
                                <h6 className="mb-lg-0">
                                  Trabalho<span className="text-danger">*</span>
                                </h6>
                              </div>
                              <div className="col-lg-8">
                                <input type="text" className="form-control" id="jobTitle"  value={requestApply?.job_title??"N/D"} />
                              </div>
                            </div>
                          </div>
                          {/* Job title */}
                          <div className="col-12">
                            <div className="row g-xl-0 align-items-center">
                              <div className="col-lg-4">
                                <h6 className="mb-lg-0">
                                Inicio <span className="text-danger">*</span>
                                </h6>
                              </div>
                              <div className="col-lg-8">
                                <input type="date" className="form-control" id="jobTitle" value={requestApply?.job_start_date} />
                              </div>
                            </div>
                          </div>
                          {/* Job title */}
                          <div className="col-12">
                            <div className="row g-xl-0 align-items-center">
                              <div className="col-lg-4">
                                <h6 className="mb-lg-0">
                                Fim <span className="text-danger">*</span>
                                </h6>
                              </div>
                              <div className="col-lg-8">
                                <input type="date" className="form-control" id="jobTitle" value={requestApply?.job_end_date} />
                              </div>
                            </div>
                          </div>
                  </AccordionDetails>
                </Accordion>


                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Educação</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                                    {/* Divider */}
                <hr className="my-3" />
                {/* Education */}
                {/* School or college name */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                        Instituicao <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                      <input type="text" className="form-control" id="collegeName" value={requestApply?.institution} />
                    </div>
                  </div>
                </div>
                {/* Year of passing */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                         Inicio <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                        <input type="date" className="form-control" id="board"  value={requestApply?.school_end_date}/>
                    </div>
                  </div>
                </div>
                    {/* Year of passing */}
                    <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                      Fim <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                      <input  type="date" className="form-control" id="board" value={requestApply?.school_start_date}/>
                    </div>
                  </div>
                </div>
                {/* Board of university */}
                <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <div className="col-lg-4">
                      <h6 className="mb-lg-0">
                        Curso <span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div className="col-lg-8">
                      <input type="text" className="form-control" id="board" />
                    </div>
                  </div>
                </div>
                {/* Divider */}
                  </AccordionDetails>
                </Accordion>

                <hr className="my-2" />
                {/* Parent detail */}
                <h5 className="mt-0">Habilidades/Disciplinas de dominio</h5>
               
                    {/* Board of university */}
                    <div className="col-12">
                  <div className="row g-xl-0 align-items-center">
                    <ul>

                        {/* {habilidades?.map((value,key)=> (value.title?.toLowerCase().includes(skill?.toLowerCase()) &&  skill != "") && (
                            <div className='bg-white w-100 p-2 border-bottom' key={key} onClick={()=>{addSkills(value,value.title);}}> <span className='p-1'>+</span> <strong>{value.title}</strong></div>
                        ))} */}

                        {requestApply.skills?.map((value,key)=>(
                            <Chip label={value} className='m-1 bg-sucess' size="small" />
                        ))}

                    </ul> 
                  </div>
                </div>

                <h5 className="mt-0">Os Nossos Pacotes</h5>
                <p className="mb-1">
             Antes de prosseguir com o formulário, leia abaixo os tópicos:
              </p>

                <div className='d-flex flex-column flex-md-row gap-3'>
                  {plans.map((item, index) => (
                    <div key={index} className='flex-fill'>
                      <PricingCardDemo planDetail={item} selectedPlan={requestApply?.payment} />
                    </div>
                  ))}
                </div>

                {/* Button */}
                <div className="d-flex mb-4 gap-2 mt-5">
                  {/* <button className="btn mainColor mb-0"></button> */}
                  <Button variant="contained"  style={{height:"50px"}} className={"shadow mainColor "} >Aprovar</Button>
                  <Button variant="contained"  style={{height:"50px"}} className={"shadow bg-danger text-white "} >Reprovar</Button>
                </div>
                
              </form>
              {/* Form END */}
        </div>


      </section>      
    );
}

export default ApplyInstructor;