import { Container,Accordion,AccordionActions,AccordionSummary,AccordionDetails,Button,TextField} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Network from '../network/networkRequests';
import { MuiColorInput } from 'mui-color-input'

const Custom = ()=>{

    const [content,setcontent] = useState({});

    const [saved,setsaved] = useState(false);

    useEffect(()=>{
        getConfigs();
    },[]);

    const getConfigs = async ()=>{
        await Network.getconfigs().then((response) => {
            setcontent(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const handlechange=(e, keyName,index, valKey)=>{
        // const nameKeyChild = e.target.name;
        const nameValChild = e.target?.value??e;
     
        content[keyName][index][valKey] = nameValChild;
        setcontent(content);
    }

    const handlesave = async ()=>{
        await Network.saveConfigs(content).then((response) => {
            console.log(response.data);
            setsaved(true);
          })
          .catch((error) => {
            console.log(error);
          });

          setTimeout(()=>{
            setsaved(false);
          },1000);
    }


    return (
        <main>
            <div className="page-content p-4">
                <Container>
                <div className='pb-3'>
                <div className="row mb-3">
                <div className="col-12 d-sm-flex justify-content-between align-items-center">
                <h1 className="h3 mb-2 mb-sm-0">Ajustes de interface</h1>
                
                </div>
            </div>
                </div>
                    {Object.keys(content).map((keyName,index)=>(
                        <Accordion key={index}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                           {keyName}
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className='d-flex flex-column gap-3'>
                                {content[keyName] instanceof Map || Array.isArray(content[keyName])?  content[keyName].map((item,ky)=>(<div className='border mb-2 w-100'  key={ky}>
                                    {item.type === "text" && <TextField id="outlined-basic" fullWidth label={item.name} name={item.name} type={item.type} variant="outlined" defaultValue={item.value} onChange={(e)=>handlechange(e,keyName,ky,"value")} />}
                                    {item.type == undefined && <TextField id="outlined-basic" fullWidth label={item.name} name={item.name} type={item.type} variant="outlined" defaultValue={item.value} onChange={(e)=>handlechange(e,keyName,ky,"value")} />}
                                    {item.type === "color" && <div className='d-flex flex-column'>
                                        <label>{item.type}</label>
                                        <MuiColorInput format="hex" value={item.color??item.bg} onChange={(e)=>handlechange(e,keyName,ky,item.color?"color":"bg")} />
                                    </div>}
                                </div>)): "Value:"+content[keyName] }
                              </div>
                            </AccordionDetails>
                            { content[keyName] instanceof Map || Array.isArray(content[keyName]) &&
                            <AccordionActions>
                                <Button>Cancel</Button>
                                <Button style={{background:saved?"green":"blue", color:"#fff", width:"150px"}} color='primary' onClick={handlesave}>
                                    {saved?"Salvo!":"Guardar"}</Button>
                            </AccordionActions>}
                        </Accordion>
                    ))}
                </Container>
            </div>
        </main>
    );
}

export default Custom;