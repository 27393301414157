import logo from './logo.svg';
import './App.css';
import './css/style.css';
import { BrowserRouter, Routes, Route,Link } from "react-router-dom";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/bootstrap-icons/bootstrap-icons.css";
import "./vendor/tiny-slider/tiny-slider.css";
import "./vendor/glightbox/css/glightbox.css";
import axios from 'axios';
import Main from "./modules/Main";
import Sidebar from "./modules/Sidebar";
import Loginform from './modules/Loginform';
import Plans from "./modules/Plans";
import Custom from "./modules/Custom";

if(localStorage.getItem("MAIN_URL") == null)
localStorage.setItem("MAIN_URL","https://api.eureca.ao/v0.1");

function App() {
  
  return (
    <div>
      {localStorage.getItem("token") == undefined?<Loginform/>: <BrowserRouter>
      <Sidebar/>
      <Routes>
          <Route path="/" element={<Main />} exact></Route>
          <Route path="/admin/plans" element={<Plans />} exact></Route>
          <Route path="/admin/custom" element={<Custom />} exact></Route>
      </Routes>
    </BrowserRouter>}
   
</div>
  );
}

export default App;
